import React, { useState } from "react"
import { encodeAsUrlString } from "../../helpers/Strings"
import { Routes } from "../../data/routes"
import { SubmitButton } from "../../components/button"
import Img from "../../components/img"
import Layout from "../../components/layout"
import TextField from "../../components/textfield"
import Seo from "../../components/seo"

const Content = {
  pageName: "Contact",
  header: "Contact Yup",
  description: "Contact Yup",
  headerImage: "contact-header.jpeg",
  headerImageAltText: "Blue background",

  // Send me form
  formHeader: "Get the Yup one-pager",
  formImage: "contact-cover.jpeg",
  formImageAltText: "Parent and child reading on smartphone",
  formText: "Learn how on-demand math tutoring works at schools like yours",
  formFirstNameLabel: "First name",
  formEmailLabel: "Email",
  formSchoolLabel: "School",
  formRoleLabel: "I am a...",
  formRoleSelect: "-- Select --",
  formRoleOptions: [
    "District Administrator",
    "Principal",
    "Teacher",
    "Tutor",
    "Student",
  ],

  formRoleOther: "Other",
  formOtherLabel: "What is your role?",
  formSubmitButton: "Send me the one-pager",
  onePager: "/downloads/Yup_Math_Tutoring_One_Pager.pdf",
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-17d3a4a8538b7d528d954a5db2874da8
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Img
          src={Content.headerImage}
          alt={Content.headerImageAltText}
          className="w-full h-full"
          cover={true}
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {Content.header}
        </h1>
      </div>
    </div>
  )
}

function SendMeForm() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/contact-sections#component-6bd457a8f982faa1eca40d712ab7278b
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [school, setSchool] = useState("")
  const [role, setRole] = useState("")
  const [other, setOther] = useState("")

  function onRoleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setRole(event.target.value)
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault()
    fetch(Routes.sendme, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeAsUrlString({
        "form-name": "leadmagnet",
        first_name: firstName,
        email,
        school,
        role,
        other,
      }),
    }).then(() => {
      window.location.replace(Content.onePager)
    })
  }

  return (
    <div className="relative">
      <div className="lg:absolute lg:inset-0">
        <div className="hidden lg:block absolute inset-y-0 right-0 w-1/2">
          <Img
            src={Content.formImage}
            alt={Content.formImageAltText}
            className="h-56 w-full lg:absolute lg:h-full"
            cover={true}
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {Content.formHeader}
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              {Content.formText}
            </p>

            <form className="space-y-4 mt-4" onSubmit={onSubmit}>
              <div className="space-y-4 md:grid md:grid-cols-2 md:space-y-0 items-center">
                <TextField
                  label={Content.formFirstNameLabel}
                  id="first_name"
                  required={true}
                  autoComplete="given-name"
                  value={firstName}
                  onChange={setFirstName}
                />
                <TextField
                  label={Content.formEmailLabel}
                  id="email"
                  required={true}
                  autoComplete="email"
                  type="email"
                  value={email}
                  onChange={setEmail}
                />
              </div>
              <TextField
                id="school"
                label={Content.formSchoolLabel}
                value={school}
                onChange={setSchool}
              />
              <div className="px-2">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  {Content.formRoleLabel}
                  <span className="mx-2 text-red font-bold">*</span>
                </label>
                <select
                  name="role"
                  id="role"
                  required
                  value={role}
                  className="border rounded-md p-2 mt-1 focus:ring-secondary-500 focus:border-secondary-500 flex-1 block w-full sm:text-sm border-gray-300"
                  onChange={onRoleChange}
                >
                  <option value="" disabled>
                    {Content.formRoleSelect}
                  </option>
                  {Content.formRoleOptions.map(role => (
                    <option value={role} key={role}>
                      {role}
                    </option>
                  ))}
                  <option value="Other">{Content.formRoleOther}</option>
                </select>
              </div>
              {role === "Other" && (
                <TextField
                  id="other"
                  label={Content.formOtherLabel}
                  required={true}
                  value={other}
                  onChange={setOther}
                />
              )}
              <div className="text-right mt-4">
                <SubmitButton text={Content.formSubmitButton} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const SendMe = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.sendme}
      />
      <Header />
      <SendMeForm />
    </Layout>
  )
}

export default SendMe
